<template>  
    <sub-menu-container class="account-menu-hint" >
        <div style="width: max-content; min-width: 279px;">
        <div class="d-flex align-content-center hint-title">
            <h5 class="title-16">{{ $t("layout.account.log_in_account") }}</h5>
        </div>
        <hr class="submenu-divider"/>
        <div class="d-flex m-0 w-100">
            <div class="d-flex justify-content-between m-0 w-100" >
                <button class="login cursor-pointer bg-white position-relative" @click="$emit('openLogin')">
                    <icon-close-session class="me-2" :color="'var(--blue-600)'" :size="22" />
                    <span class="action size-15">{{ $t("layout.account.log_in") }}</span>
                </button>
                <div class="d-flex size-14 login-right align-items-center" @click="$emit('openSignIn')" style="margin-left: 30px;">
                    <span class="weight-400 color-dark-gray-200 login-question" style="margin-right: 10px;">{{ $t("layout.account.are_you_new") }}</span>
                    <span class="weight-500 hover-color-dark-main-red color-blue-600 login-signup hover-underline cursor-pointer">{{ $t("layout.account.register") }}</span>                
                </div>
            </div>
        </div>
        </div>
</sub-menu-container>
</template>

<script>
export default {
    name: 'AccountHint',
    components: {
        //general container
        SubMenuContainer: () => import('@/modules/layout/components/navbar/menus/submenus/SubMenuContainer.vue'),
        IconCloseSession: () => import("../../../../../../common/svg/iconCloseSession.vue"),
    }
}
</script>

<style scoped>
/* fix the triangle not showing in max-width lg & the menu-hint container shadow over it*/
.account-menu-hint {
    padding: 15px;
    display: block;
    z-index: 10 !important;

    left: auto;
    right: 0;
}
.hint-title {
    height: 20px;
}
.submenu-divider {
    margin: 6px 0 12px 0;
}

.show-start {
    opacity: 0 !important;
    transform: translateY(100%);
}
@keyframes show-hint-frames {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}
.show-default {
    display: block;
    animation: show-hint-frames;
    animation-duration: 0.3s;
}
.show-normal {
    display: block;
}
@keyframes hide-hint-frames {
    0% {
        opacity: 1;
        transform: translateY(0px);
    }
    50% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}
.show-end {
    animation: hide-hint-frames;
    animation-duration: 0.3s;
    animation-fill-mode: both;
}

    span.action {
        transition: color 0.20s ease-in-out;
    }

    button.login {
        border: 1px solid transparent;
        border-radius: 5px;

        padding: 5px 0px;
        padding-right: 7px;
        padding-left: 3px;
        
        /* border-size */
        margin-top: -1px;
        margin-bottom: -1px;

        margin-left: 10px;

        height: max-content;
    }    
    
    button.login > svg {
        padding-bottom: 4px;
    }
    .login-right {
        margin-left: 30px;
    }

    button.login:hover span.action {
        color: var(--blue-600)
    }

    button.login:hover > .icon-close-session::v-deep #Path_1965{
        transform: translate(-344px, -90.5px);
        -moz-transform: translate(-344px, -90.5px); /* Firefox */
    }
    button.login > .icon-close-session::v-deep > #Path_1965 {
        transition: transform .25s var(--alt-ease-in-out);
    }
    button.login::before, button.login::after {
        position: absolute;

        border: 1px solid transparent;
        border-radius: 5px;

        box-sizing: inherit;
        content: '';
        width: 0;
        height: 0;
    }
    button.login::before {
        top: 0;
        right: 0;
    }
    button.login::after {
        bottom: 0;
        left: 0;
    }
    button.login:hover::before, button.login:hover::after {
        width: 100%;
        height: 100%;
    }
    button.login:hover::after {
        border-bottom-color: var(--blue-600);
        border-right-color: var(--blue-600);

        transition: border-color 0.15s ease-in-out, height 0.15s ease-in-out 0.2s, width 0.15s ease-in-out 0.1s;
    }
    button.login:hover::before {
        border-top-color: var(--blue-600);
        border-left-color: var(--blue-600);

        transition:  border-color 0.15s ease-in-out 0.25s, height 0.15s ease-in-out 0.45s, width 0.15s ease-in-out 0.35s;
    }

@media (max-width: 575px) {
    .account-menu-hint {
        position: fixed;
        top: 59px;
        right: 50%;
        transform: translateX(50%);
    }
    .show-start {
        transform: translate(50%, 100%);
    }
    @keyframes show-hint-frames {
        from {
            opacity: 0;
            transform: translate(50%, 20px);
        }
        to {
            opacity: 1;
            transform: translate(50%, 0px);
        }
    }
    @keyframes hide-hint-frames {
        0% {
            opacity: 1;
            transform: translate(50%, 0px);
        }
        50% {
            opacity: 0;
            transform: translate(50%, 20px);
        }
        100% {
            opacity: 0;
            visibility: hidden;
        }
    }
}
</style>